<template>
  <v-scale-transition>
    <kits-panel :kits-items="subscriberGroup">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Campaigns
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
    }
  },
  computed: {
    subscriberGroup: function () {
      const menu = [
        // {
        //   icon: 'mdi-car-sports',
        //   path: '/campaign_senang_win',
        //   title: 'Senang Win',
        //   desc: 'Kempen Senang Win',
        // },
        {
          icon: 'mdi-cellphone-arrow-down',
          path: '/mnp_noncelcom',
          title: 'Mnp Non-Celcom',
          desc: 'Mnp Promotion',
        },
        {
          icon: 'mdi-cash-fast',
          path: '/unl35Campaign',
          title: 'UNL35 Campaign',
          desc: 'Double Ong Liao x Ramadhan Upgrade',
        },
        {
          icon: 'mdi-car-sports',
          path: '/campaign_mega',
          title: 'Dealer Mega Campaign',
          desc: 'Mega Campaign',
        },
      ]

      return menu
    },
  },
}
</script>
